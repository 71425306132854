<template>
  <div style="margin-bottom: 50vh">
    <div class="title q-mb-lg">
      <h1>PROFCHECK</h1>
      <p class="paragraph-1 q-mt-md">Your Prof Rating Platform</p>
    </div>
    <div class="div-block-1-3"></div>
    <div class="centered-content column">
      <q-input
        outlined
        @update:model-value="searchProf"
        type="search"
        :label="$t('profcheck.search_input')"
        class="search-field"
        :hint="$t('profcheck.search_hint')"
        dense
        style="min-width: 300px"
      >
        <template v-slot:after>
          <q-btn round dense flat size="lg" icon="search" />
        </template>
      </q-input>
      <div class="col-12 text-center">
            <router-link to="/professor/suggest" class="text-grey-8">
        <div class="text-body1 text-italic"> {{ $t('profcheck.cant_find_prof') }} </div>
            </router-link>
    </div>
    </div>
    <div class="row justify-center">
      <div
        v-if="rows.length > 0"
        class="col-xs-12 col-md-8"
        style="margin-top: 5vh"
      >
        <q-table
          id="profTable"
          wrap-cells
          style="height: 600px"
          title="Professors"
          :title-class="'text-h5'"
          :rows="rows"
          :columns="columns"
          row-key="_id"
          virtual-scroll
          :rows-per-page-options="[0]"
          @row-click="onRowClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { scroll } from 'quasar';
import { QSpinnerGears } from "quasar";
import { ref } from "vue";
import { useUserStore } from "@/store/user.store";
import { useProfStore } from "@/store/prof.store";
import router from "../router";

export default {
  setup() {
    const store = useUserStore();
    const profStore = useProfStore();

    return {
      columns: [
        {
          name: "name",
          label: "Name",
          field: (row) => `${row.title ?? ""} ${row.fname} ${row.lname}`,
          align: "left",
          sortable: true,
        },
        {
          name: "institute",
          label: "Institute",
          field: "institute",
          align: "left",
          sortable: true,
        },
        {
          name: "studies",
          label: "Subjects",
          field: (row) => row.subjects.join(", "),
          align: "left",
          sortable: true,
        },
      ],
      text: "",
      profStore: ref(profStore),
      rows: ref([]),
      store,
    };
  },
  computed: {
    dense() {
      return this.$q.platform.is.desktop ? " " : "";
    },
  },
  methods: {
    searchProf(val) {
      val = val ? val.toLowerCase() : "";
      this.rows.length = 0;

      if (val.length > 2) {
        this.rows.push(
          ...this.profStore.professors.filter((prof) => {
            if (
              Object.keys(prof).some((key) =>
                String(prof[key]).toLowerCase().includes(val)
              )
            ) {
              return prof;
            }
          })
        );
        // const { setVerticalScrollPosition } = scroll;
        //
        // const element = document.getElementById('profTable');
        // if (!element) {
        //     return;
        // }
        //
        // const target = getScrollTarget(element)
        // const offset = element.offsetTop - element.scrollHeight
        // const duration = 1000
        // setScrollPosition(target, offset, duration)
        //
        // setVerticalScrollPosition(element, element.offsetHeight);
      } else {
        this.rows.push(...this.profStore.professors);
      }
    },
    async onRowClick(evt, row) {
      await this.profStore.setSelected(row._id);
      console.log("selected prof", this.profStore.selected);
      router.push("/professor");
    },
  },
  async mounted() {
    const notif = this.$q.notify({
      group: false,
      color: "primary",
      textColor: "white",
      spinner: QSpinnerGears,
      message: "Loading professors...",
      timeout: 0,
      position: "bottom",
    });

    try {
      const response = await this.profStore.fetchProfessors();

      if (response.status === 200) {
        notif({
          color: "positive",
          textColor: "white",
          message: "Professors loaded",
          timeout: 2000,
          spinner: false,
          icon: "check",
        });

        this.rows.length = 0;
        this.rows.push(...response.data);
      }
    } catch (e) {
      console.error(e);
      notif({
        color: "negative",
        textColor: "white",
        message: e.message,
        spinner: false,
        icon: "error",
        timeout: 2000,
      });
    }
  },
};
</script>

<style  scoped>
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

#profcheck-logo {
  position: absolute;
  top: 0;
  right: 0;
  height: 55vw;
  z-index: -1;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
}
.paragraph-1 {
  font-family: Staatliches, sans-serif;
  color: #5bbdf4;
  font-weight: 400;
  text-align: center;
  font-size: 2vw;
  margin-top: -5%;
}
.div-block-1-3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: 60%;
  height: 100%;
  background-image: url("../assets/profcheck-thumb-kontur.svg");
  background-position: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}
.title h1 {
  font-family: "Staatliches", sans-serif;
  color: #5bbdf4;
  font-size: 13vw;
  font-weight: 400;
  padding: 0%;
  margin: 0%;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.1em;
}
.search-field {
  width: 30%;
}
.suggest-prof {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.suggest-prof button {
  border: none;
  background: none;
  cursor: pointer;
}
@media screen and (max-width: 321px) {
  .div-block-1-3 {
    top: 10%;
    width: 60%;
    left: 20%;
    height: 40%;
    opacity: 1;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .div-block-1-3 {
    top: 4%;
    width: 60%;
    left: 20%;
    height: 40%;
    opacity: 1;
  }
  .title h1 {
    font-size: 20vw;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .div-block-1-3 {
    top: 5%;
    width: 80%;
    left: 10%;
    height: 40%;
    opacity: 0.7;
  }
  .title h1 {
    font-size: 20vw;
  }
  .paragraph-1 {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .div-block-1-3 {
    top: 2%;
    height: 60%;
  }
  .title h1 {
    font-size: 20vw;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .div-block-1-3 {
    top: 0;
    height: 65%;
  }
  .title h1 {
    font-size: 20vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .div-block-1-3 {
    top: 0;
    height: 75%;
  }
}

@media screen and (min-width: 1441px) {
  .div-block-1-3 {
    top: 0;
    height: 95%;
  }
}
</style>